<template>
  <div>
    <div class="AI">
      <p v-if="time!=''">最新AI建议时间：{{time}}</p>
      <p v-if="time==''">最新AI建议时间: </p>
      <hr />
      <button class="Advice_btn" @click="GET_AI">获取建议</button>
      <div class="AIProposal_text" v-if="AIProposal!=''">
        {{ AIProposal }}
      </div>
      <div class="AIProposal_text" v-if="AIProposal == ''">正在获取最新AI建议...</div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      AIProposal: "",
      time: "",
    };
  },
  mounted() {
    this.GET_AI();
  },
  methods: {
    GET_AI() {
      // this.GET_AIProposal_success();
      this.AIProposal = "";
      this.time = "";
      axios
        .get("https://api.qiyao.ink/IoTA/ai/ProposalAI_AS", {
          params: {
            ASID: 1,
          },
          headers: {
            Authorization: `Bearer ${this.$store.state.phoneCaptcha}`,
          },
          timeout: 500000, // 设置超时时间为5秒
        })
        .then((res) => {
          this.AIProposal = res.data.data.AIProposal;
          this.time = res.data.data.time;
          console.log("AI建议", res.data);
          // res.data.code == 200 ? this.AIProposal_success() : this.AIProposal_error();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    GET_AIProposal_success() {
      this.$notify({
        title: "获取最新AI建议中~",
      });
    },
    AIProposal_success() {
      this.$notify({
        title: "最新AI建议请求成功",
        type: "success",
      });
    },
    AIProposal_error() {
      this.$notify.error({
        title: "最新AI建议请求失败",
        message: "请检查网络连接",
        type: "error",
      });
    },
  },
};
</script>
<style scoped>
.AI {
  position: relative;
  width: 40vw;
  height: 37vw;
  border-radius: 1vw;
  background-color: hsla(180, 100%, 50%, 0.2);
  box-shadow: 3px 3px 4px 0 rgb(7, 226, 233);

  /* 设置背景颜色为带有50%透明度的蓝色 */
  /* box-shadow: 0px 3px 6px #7474fabc; */
}
.AI p {
  position: absolute;
  font-weight: bold;
  color: aliceblue;
  top: -0.8vw;
  left: 0.6vw;
  font-size: 1.3vw;
}
.AI hr {
  position: absolute;
  height: 1px;
  top: 2.3vw;
  background: #ffffff;
  border: none;
  width: 40vw;
}
.Advice_btn {
  position: absolute;
  top: 0.4vw;
  left: 34.5vw;
  height: 2vw;
  width: 5vw;
  border-radius: 1vw;
  font-weight: bold;
  font-size: 1vw;
  color: rgb(255, 255, 255);
  background-color: hsla(180, 100%, 50%, 0.453);
  box-shadow: 1px 1px 3px 0 rgb(7, 226, 233);
  cursor: pointer;
  padding: 0px 0px; /* 按钮的内边距 */
  /* box-shadow: 2px 3px 3px #65656582; */
  border: none; /* 去掉边框 */
}
.Advice_btn:hover{
  background-color: hsla(180, 100%, 50%, 0.382);
  color: rgba(255, 255, 255, 0.751);
}
.AIProposal_text {
  color: #ffffff;
  margin-top: 55px;
  position: absolute;
  margin-left: 10px;
  margin-right: 22px;
  max-height: 32.3vw;
  overflow: auto;
  white-space: pre-line;
  width: 38.8vw;
}

.AIProposal_text::-webkit-scrollbar {
  width: 0px; /* 设置滚动条宽度 */
}

.AIProposal_text::-webkit-scrollbar-thumb {
  background-color: transparent; /* 设置滚动条轨道颜色为透明 */
}
</style>