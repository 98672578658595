<template>
  <div>
    <!-- 按钮触发显示窗口 -->
    <!-- <button @click="showModal">显示窗口</button> -->

    <!-- 窗口 -->
    <div
      v-if="this.$store.state.isModalVisible"
      class="modal"
      @click="closeModal"
    >
      <!-- 内容区域 -->
      <div class="modal-content" @click.stop>
        <!-- 关闭按钮 -->
        <span class="close" @click="closeModal"
          ><img
            class="close_img"
            src="../img/Device_Management/close_img.png"
            alt="Close"
        /></span>
        <div class="title">
          <p>AI农业专家</p>
        </div>
        <!-- Chat_area 区域，可以滚动 -->
        <div class="Chat_area" ref="chatArea">
          <div v-for="(message, index) in conversation" :key="index">
            <div v-if="message.role === 'user'">
              <!-- 用户消息 -->
              <div class="message-container">
                <img
                  src="https://gd-hbimg.huaban.com/c52c83dae6625e013ed40f83ab9efa559dbd2d7ed638-jgmPrQ_fw240webp"
                  alt=""
                  class="avatar"
                />
                <div class="message-text">{{ message.text }}</div>
                <hr class="message-hr" />
              </div>
            </div>
            <div v-else>
              <!-- AI消息 -->
              <div class="message-container">
                <img src="../img/GPT.png" alt="" class="avatar" />
                <div class="message-text">{{ message.text }}</div>
                <hr class="message-hr" />
              </div>
            </div>
          </div>
        </div>

        <!-- 输入框 -->
        <input
          ref="enterText"
          type="text"
          class="Enter_text"
          @keyup.enter="sendMessage"
          @keyup.esc="closeModal"
        />
        <!-- 发送按钮 -->
        <button class="Send" @click="sendMessage">发送</button>
        <div class="a"></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  data() {
    return {
      isModalVisible: false,
      chatMessages: [],
      aiMessages: [], // 新增的数组用于存储AI的回答
      conversation: [], // 对象数组，包含问题和回答
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
      this.$store.commit("set_isModalVisible", true);
      document.body.style.overflow = "hidden";
      // this.chatMessages = []; // 清空聊天消息数组
    },
    closeModal() {
      this.isModalVisible = false;
      this.$store.commit("set_isModalVisible", false);
      document.body.style.overflow = "auto";
    },
    sendMessage() {
      const inputText = this.$refs.enterText.value.trim();
      if (inputText !== "") {
        const userMessage = {
          role: "user",
          text: inputText,
        };
        this.conversation.push(userMessage);
        this.$refs.enterText.value = "";

        // 发送消息后获取AI建议
        axios
          .get("https://api.qiyao.ink/IoTA/ai/AgricultureExpertAI", {
            params: {
              Issue: inputText,
            },
            headers: {
              Authorization: `Bearer ${this.$store.state.phoneCaptcha}`,
            },
            timeout: 500000,
          })
          .then((res) => {
            const aiMessage = {
              role: "ai",
              text: res.data.data.AIReply,
            };
            this.conversation.push(aiMessage);

            // 滚动到最新的AI消息
            this.$nextTick(() => {
              this.scrollToBottom();
            });

            console.log("AI建议", res.data.data);
            console.log("AI建议时间", res.data.data.time);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    },

    // 新增方法用于滚动到底部
    scrollToBottom() {
      const chatArea = this.$refs.chatArea;
      chatArea.scrollTop = chatArea.scrollHeight;
    },
  },
};
</script>
  
  <style scoped>
/* 遮罩层样式 */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

/* 内容区域样式 */
.modal-content {
  background: #dce9f9;
  padding: 20px;
  border-radius: 20px;
  width: 50vw;
  height: 33vw;
  position: relative;
  overflow: hidden;
}

/* 关闭按钮样式 */
.close {
  position: absolute;
  top: 0.4vw;
  right: 0.4vw;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
}
.close_img {
  width: 3vw;
}
.title {
  position: absolute;
  text-align: left;
  margin-bottom: 20px;
  top: 0;
  left: 0;
  width: 55vw;
  height: 3.8vw;
  background: #b8d3f0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.title p {
  font-size: 2vw;
  color: #346e81;
  margin-left: 1vw;
  margin-top: 0.6vw;
}
.Chat_area {
  position: absolute;
  height: 27vw;
  width: 52.2vw;
  /* border: 1px solid black; */
  top: 3.8vw;
  left: 0;
  overflow-y: scroll; /* 允许 Chat_area 区域滚动 */
}

/* 隐藏滚动条 */
.Chat_area::-webkit-scrollbar {
  width: 0.8em;
}

.Chat_area::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.Enter_text {
  position: absolute;
  top: 31.4vw;
  left: 15px;
  height: 3vw;
  width: 44vw;
  font-size: 1.6vw;
  border-radius: 20px;
  background: #b7d2f0;
  text-indent: 0.6vw;
}
.Send {
  position: absolute;
  top: 31.5vw;
  left: 46.2vw;
  height: 3.1vw;
  width: 80px;
  border-radius: 20px;
  background: #b7d2f0;
  border: none;
  cursor: pointer;
  font-size: 21px;
  color: #334e6b;
  font-weight: 600;
  box-shadow: 2px 3px 2px #334e6b61;
}

.message-container {
  display: flex;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-top: 0.5vw;
  position: relative;
  align-items: flex-start;
}

.avatar {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  margin-top: 7px;
  border-radius: 100px;
}

.message-text {
  margin-top: 15px;
  font-size: 21px;
  color: #3c6592;
  text-align: left;
  white-space: pre-line;
  margin-bottom: 15px;
}
.message-hr {
  position: absolute;
  width: 100%;
  bottom: -17px;
}
</style>
  