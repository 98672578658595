import { render, staticRenderFns } from "./Carbon_Dioxide.vue?vue&type=template&id=4750a0a0&scoped=true"
import script from "./Carbon_Dioxide.vue?vue&type=script&lang=js"
export * from "./Carbon_Dioxide.vue?vue&type=script&lang=js"
import style0 from "./Carbon_Dioxide.vue?vue&type=style&index=0&id=4750a0a0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4750a0a0",
  null
  
)

export default component.exports